/*-----------------------------------------------------------------------------------
    
    Template Name: Dking - Multipurpose eCommerce HTML Template
    Version: 1.0
    
-----------------------------------------------------------------------------------
    
    CSS INDEX
    ===================
    1. Template default CSS
    2. Header style
    3. Slider style
    4. Coupon style
    5. Banner style
    6. Product style
    7. Animation style
    8. Deal product style
    9. Section title style
    10. Categories style
    11. Services style
    12. Brand logo style
    13. Blog style 
    14. Footer style
    15. Subscribe style
    16. Product details style
    17. Instagram style
    18. Testimonial style
    19. About style
    20. Sidebar style
    21. Shop style
    22. Breadcrumb style
    23. Contact style
    24. Cart style
    25. Login register style
    26. My account style
    27. Checkout style
    28. Order complete style
    29. Compare style
    30. Order tracking style
    31. Others style
    
-----------------------------------------------------------------------------------*/
@import 'variabls';
@import 'common';
@import 'header';
@import 'slider';
@import 'coupon';
@import 'banner';
@import 'product';
@import 'animation';
@import 'deal-product';
@import 'section-title';
@import 'categories';
@import 'services';
@import 'brand-logo';
@import 'blog';
@import 'footer';
@import 'subscribe';
@import 'product-details';
@import 'instagram';
@import 'testimonial';
@import 'about';
@import 'sidebar';
@import 'shop';
@import 'breadcrumb';
@import 'contact';
@import 'cart';
@import 'login-register';
@import 'my-account';
@import 'checkout';
@import 'order-complete';
@import 'compare';
@import 'order-tracking';
@import 'others';




